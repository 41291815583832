<template>
  <v-dialog
    v-model="isOpen"
    @click:outside="$emit('onClose')"
    persistent
    max-width="800px"
  >
    <v-tabs value="slug" background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab style="display:flex;flex-direction:column;">
        <span style="font-size:1.2rem;">
          Ajouter une personne
        </span>
      </v-tab>
      <v-icon class="close-icon" @click="$emit('onClose')">mdi-close</v-icon>
    </v-tabs>

    <div class="modal__content">
      <v-tabs-items>
        <div class="p-4">
          <ValidateForm @validated="submit" :loading="loading">
            <InputSelect
              v-model="user.Lien_Parente"
              :items="lienParentes"
              label="Lien de parenté"
            ></InputSelect>
            <InputText
              v-model="user.Nom"
              rules="required|min:2|max:250|regexname|dashCounter"
              label="Nom"
            ></InputText>
            <InputText
              v-model="user.Prenom"
              rules="required|min:2|max:250|regexname"
              label="Prénom"
            ></InputText>
            <InputTelephone
              v-model="user.Tel_Mobile"
              label="Téléphone mobile"
              required
            ></InputTelephone>
            <InputText
              v-model="user.Email"
              rules="email"
              label="Adresse courriel"
            ></InputText>

            <div class="d-flex mt-5 justify-content-center">
              <v-btn type="submit" class="btn btn-primary" :loading="loading">{{
                textSubmit
              }}</v-btn>
            </div>
          </ValidateForm>
        </div>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions} from 'vuex'
import InputText from "@/components/utils/input.vue";
import ValidateForm from "@/components/utils/form.vue";
import InputSelect from "@/components/utils/selectWithInput.vue";
import InputTelephone from "@/components/utils/inputTelephone.vue";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import { mapActions } from "vuex";

export default {
  components: { ValidateForm, InputText, InputSelect, InputTelephone },
  data: () => ({
    user: {},
    lienParentes: ["Mère", "Père", "Grand-parent", "Époux/Épouse", "Autre"],
    loading: false,
  }),

  async created() {
    if (this.context.userPAP) {
      this.user = { ...this.context.userPAP };
    }
  },

  computed: {
    textSubmit() {
      if (this.context.userPAP) return "Mettre à jour";
      return "Ajouter";
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["updateUtilisateurPAP"]),

    async submit() {
      this.loading = true;
      try {
        if (!this.user.ID_Utilisateur) {
          this.user.ID_Utilisateur = this.context.ID_Utilisateur;
        }

        await this.updateUtilisateurPAP({ ...this.context, user: this.user });
        this.$emit("onUpdated", this.user);
        if (this.context.userPAP) {
          success_notification("Informations mises à jour");
        } else {
          success_notification("Personne ajoutée");
        }
      } catch (e) {
        console.log(e);
        error_notification();
      }
      this.loading = false;
    },
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.modal__content {
  overflow-x: hidden;
}
</style>
